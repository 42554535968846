import React from 'react'
import PropTypes from 'prop-types'
import ThemeProvider from 'styles/theme-provider'
import { Transition, TransitionGroup } from 'react-transition-group'
import { gsap, ScrollSmoother } from 'gsap-wrapper'
import GlobalStyle from 'styles/global'
import 'styles/fonts.css'
import { LayoutWrapper, PageWrapper, ContentWrapper } from './index.styled'
import Header from 'components/header'

const LayoutContent = ({ children, location }) => {
  return (
    <TransitionGroup component={null}>
      <Transition key={location.pathname} timeout={{ enter: 1000, exit: 1000 }}>
        {(status) => (
          <div className={`page ${status}`}>
            <LayoutWrapper>{children}</LayoutWrapper>
          </div>
        )}
      </Transition>
    </TransitionGroup>
  )
}

const Layout = (props) => {
  const { children, location } = props
  const refWrapper = React.useRef(null)
  const scrollSmoother = React.useRef(null)

  React.useLayoutEffect(() => {
    const gsapContext = gsap.context(() => {
      scrollSmoother.current = ScrollSmoother.create({
        content: '.home-content',
        wrapper: '.home-wrapper',
        smooth: 2,
        effects: true,
        // normalizeScroll: true,
      })
    }, [refWrapper])

    return () => gsapContext.revert()
  }, [children])

  return (
    <ThemeProvider location={location} scrollSmoother={scrollSmoother}>
      <div className="home-wrapper" ref={refWrapper}>
        <Header />
        <div className="home-content">
          <PageWrapper>
            <GlobalStyle />
            <ContentWrapper>
              <LayoutContent location={location}>{children}</LayoutContent>
            </ContentWrapper>
          </PageWrapper>
        </div>
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
