import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html, body{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #4D4D4F;
    line-height: 1.25;
    background: #ffffff;
    min-width: 320px;
  }
  body{
    overflow-y: scroll;
  }
  body.hidden {
    height: 100dvh !important;
  }
  body.hidden .home-wrapper {
    height: 100dvh;
    overflow: hidden;
  }
  html, body, A, input, select, textarea{
    font-family: 'Helvetica Neue LT Pro', Arial, Tahoma, Geneva, Kalimati, sans-serif;
  }
  A{
    text-decoration: none;
    color: #4D4D4F;
    transition: all 0.25s ease 0s;
    outline: none;
  }
  A:hover{
    text-decoration: underline;
  }
  input, select, textarea {
    vertical-align: top;
    outline: none;
  }
  textarea {
    resize: vertical;
  }
  *{ -webkit-tap-highlight-color: transparent; box-sizing: border-box; }
  img, object, iframe, svg{ border: 0; vertical-align: top; }
  svg path, svg circle, svg line{ transition: all 0.25s ease 0s; }
  .home-content {
    will-change: transform;
  }
`

export default GlobalStyle
