import { useState, useEffect } from 'react'
export const MOBILE = 768

export default function useWindowSize() {
  const isSSR = typeof window === 'undefined'
  const [windowSize, setWindowSize] = useState({
    width: isSSR ? 450 : window.innerWidth,
    height: isSSR ? 400 : window.innerHeight,
    isMobile: false,
  })
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth < MOBILE,
      })
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}
